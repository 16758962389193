// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { InView } from 'react-intersection-observer'

// This component is used to check if the element is in the view port, and if it is, it will call the callback function
const WithInView = ({ children, callbackOnVisible, triggerCallbackOnce = true, triggerCallbackInstantly = false }) => {
	const [inView, setInView] = useState(false)
	const [isBeenVisible, setIsBeenVisible] = useState(false)

	const triggerCallback = () => {
		callbackOnVisible()

		if (triggerCallbackOnce) {
			setIsBeenVisible(true)
		}
	}

	useEffect(() => {
		if (triggerCallbackInstantly) {
			triggerCallback()
		}
	}, [])

	useEffect(() => {
		if (inView && !isBeenVisible) {
			triggerCallback()
		}
	}, [inView])

	return <InView onChange={setInView}>{({ ref }) => <div ref={ref}>{children}</div>}</InView>
}

export default WithInView
