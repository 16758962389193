// @ts-nocheck
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import TypographyPro from '../../themes/TypographyPro'

const Message = styled(TypographyPro)`
	background-color: var(--footerAndDarkBackgrounds);
	color: var(--optionalBackground);
	width: 100%;
	text-align: center;
	padding: ${({ $useSubHeader }) => !$useSubHeader && '12px 0'};

	@media (max-width: 576px) {
		padding: ${({ $useSubHeader }) => !$useSubHeader && '10px 0'};
	}
`

/**
 * Use this to provide any HTML message on-screen
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const GlobalMessage = (props) => (
	<Message variant="BodySmall" component="div" $useSubHeader={props.useSubHeader}>
		{props.children}
	</Message>
)

export default GlobalMessage

// GlobalMessage.propTypes = {
// 	children: PropTypes.node.isRequired,
// }
