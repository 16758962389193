// @ts-nocheck
import React from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'

import Grid from '@material-ui/core/Grid'

import TypographyPro from 'themes/TypographyPro'
import { mediaQueriesBreakpoints, respondAbove } from 'styles/mixins'

import oStorage from 'utils/o-storage'
import { getTranslatedTextByKey } from 'utils/utils'

const StyledLogo = styled.div`
	height: 35px;
	width: 120px;

	${respondAbove.md`
		margin: 0 auto;
		height: 45px;
		width: 218.55px;
	`}

	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	background-image: ${({ $logoUrl }) => `url('${$logoUrl}')`};
	flex-grow: 1;
`

const StyledTitle = styled(TypographyPro)`
	color: var(--strokeMenuTitle);
	white-space: nowrap;
`

const TitleContainer = styled(Grid)`
	text-align: center;
	text-transform: uppercase;
	justify-content: center;
	color: var(--strokeMenuTitle);
	align-items: center;
	margin-top: 40px;

	${respondAbove.md`
		margin-top: 60px;
		margin-bottom: 50px;
	`}
`

const SignedOut = inject(
	'User',
	'Infra'
)(
	observer(({ Account, User, Infra }) => {
		const isMobileView = !useMediaQuery({ query: mediaQueriesBreakpoints.lg })
		let logoUrl

		if (Infra?.appParams?.eCommerce) {
			if (isMobileView) {
				logoUrl = Infra?.appParams?.assets?.logoMobileHeader
			} else {
				logoUrl = Infra?.appParams?.assets?.logoDesktopHeader
			}
		} else {
			// this is for non-ecommerce
			logoUrl = oStorage.get('logo')
		}

		return (
			<Grid container>
				<StyledLogo $logoUrl={logoUrl} />
				<TitleContainer container direction="column">
					<StyledTitle variant="h2">{getTranslatedTextByKey('eCommerce.accountSettings.youreSignedOutFirstLine')}</StyledTitle>
					<StyledTitle variant="h2">{getTranslatedTextByKey('eCommerce.accountSettings.youreSignedOutSecondLine')}</StyledTitle>
				</TitleContainer>
			</Grid>
		)
	})
)

export default SignedOut
