import React, { useMemo } from 'react'
import styled from 'styled-components'

import { mediaQueriesBreakpoints, respondAbove } from 'styles/mixins'
import BannerCarousel from 'components/common/BannerCarousel'
import { inject, observer } from 'mobx-react'
import type _Application from 'mobx/Application'

const SlideBanner = styled.picture`
	position: relative;
	cursor: pointer;

	& source,
	img {
		width: 100%;
		aspect-ratio: 1200 / 900;
		object-fit: cover;

		${respondAbove.sm`
			aspect-ratio: 2000 / 400;
		`}
	}
`

const BannerContainer = styled.div<{ $homepageBanners: boolean }>`
	width: 100%;
	aspect-ratio: 1200 / 900;
	overflow: hidden;

	${({ $homepageBanners }: any) => !$homepageBanners && 'max-width: 1320px;'};

	${respondAbove.sm`
		aspect-ratio: 2000 / 400;
	`}
`

export interface Banner {
	id: string
	imgMobileUrl: string
	imgDesktopUrl: string
	target?: string
	urlQs?: string
}

interface BannersProps {
	Application: typeof _Application
	banners: Banner[]
	onBannerAndOfferClick: (banner: Banner) => void
	homepageBanners: any
	onSlideChange: (data: any) => void
}

const Banners = ({ banners, onBannerAndOfferClick, homepageBanners, Application, onSlideChange }: BannersProps) => {
	const isImageURLValid = (url: string) => {
		try {
			// Will throw an error if url is not valid
			// eslint-disable-next-line no-new
			new URL(url)
		} catch (e) {
			return false
		}
		return /\/[\w-]+\.(png|jpeg|jpg|webp|avif|gif)(|\?[^/]*)$/i.test(url)
	}

	const isBannerValid = (banner: unknown) => {
		if (typeof banner !== 'object' || !banner) {
			throw new Error('banner is not a valid object.')
		} else if ((!(banner as Banner).urlQs && (banner as Banner).target !== 'lp') || typeof (banner as Banner).urlQs !== 'string') {
			throw new Error("banner's urlQs is not valid.")
		} else if (!isImageURLValid((banner as Banner).imgMobileUrl)) {
			throw new Error('mobile image URL is invalid.')
		} else if (!isImageURLValid((banner as Banner).imgDesktopUrl)) {
			throw new Error('desktop image URL is invalid.')
		} else {
			return true
		}
	}

	const slideBanners = useMemo(
		() =>
			banners
				?.filter((banner: any, index: number) => {
					try {
						isBannerValid(banner)
					} catch (e) {
						console.warn(`Banner ${index} is invalid: ${e}`)
						return false
					}
					return true
				})
				.map((banner: any, index: number) => (
					<SlideBanner
						id={`${Application.page}-page-banner-${index + 1}-${
							Application.isMobileView || Application.isMobileApp ? 'mobile' : 'desktop'
						}-click`}
						key={banner.id}
						onClick={() => onBannerAndOfferClick({ ...banner, index })}
					>
						<source media={mediaQueriesBreakpoints.sm} srcSet={banner.imgDesktopUrl} />
						<img src={banner.imgMobileUrl} alt="Banner offer" data-testid={`banner-${index + 1}`} />
					</SlideBanner>
				)),
		[Application.page, banners, isBannerValid, onBannerAndOfferClick]
	)

	return (
		!!banners?.length && (
			<BannerContainer $homepageBanners={homepageBanners} data-testid="banner-carousel">
				<BannerCarousel onSlideChange={onSlideChange} showPagination loop autoplay autoplayDelay={5000}>
					{slideBanners}
				</BannerCarousel>
			</BannerContainer>
		)
	)
}

export default inject('Application')(observer(Banners))
