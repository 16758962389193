import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'
import SmartLink from 'static/components/SmartLink'
import CategoryItem from './CategoryItem'
import { getTranslatedTextByKey, codeToLocale, isMobileApp, getLocaleStr } from 'utils/utils'
import { CONSTANTS, ORDER_TYPES } from 'utils/constants'
import { isEmpty } from 'lodash-es'
import type SectionID from 'types/SectionID'
import type ItemID from 'types/ItemID'
import type Title from 'types/Title'
import WithInView from 'HOCs/withInView'
import SectionTitleWithDividerBase from 'components/common/SectionTitleWithDivider'
import { StoreContext } from 'contexts/StoreContext'
import { isNextJS } from '../../../../utils/nextUtils'
import { FeatureFlagEnum } from 'types/constants.types'
import { useRouter } from 'next/router'

const Wrapper = styled.div`
	@media (max-width: 899px) {
		width: unset;
	}

	@media (max-width: 576px) {
		margin-top: unset;
		position: unset;
		top: unset;
		width: unset;
	}
`

const ContainerGrid = styled.div`
	gap: 20px;
	margin: 0 0 40px 0;
	display: grid;
	grid-template-columns: repeat(4, minmax(1px, 1fr));

	@media (max-width: 480px) {
		grid-template-columns: repeat(2, minmax(1px, 1fr));
	}

	@media (min-width: 481px) and (max-width: 1200px) {
		grid-template-columns: repeat(3, minmax(2px, 1fr));
	}
`

const SectionTitleWithDivider = styled(SectionTitleWithDividerBase)`
	margin-bottom: 25px;
`

interface CategoryItemType {
	sectionId: SectionID
	itemId: ItemID
	image: string
	title: Title
}

type SectionItemsType = Record<string, CategoryItemType[]>

interface BrowseCategoriesSectionProps {
	User: any
	Home: any
	Application: any
	Infra: any
	AddressManager: any
}

const BrowseCategoriesSection = inject(
	'User',
	'Home',
	'Application',
	'Infra',
	'AddressManager'
)(
	observer(({ User, Home, Application, Infra, AddressManager }: BrowseCategoriesSectionProps) => {
		const [sectionItems, setSectionItems] = useState<SectionItemsType>({})
		const { locale: localData } = Home
		const currentOrderType = CONSTANTS.DELIVERY_METHODS.DELIVERY === User.orderType ? ORDER_TYPES.DELIVERY : ORDER_TYPES.PICKUP
		const router = useRouter()
		const { setStore, nextJS } = useContext(StoreContext)

		// @ts-ignore
		const locale = codeToLocale[User.preferredLanguage] || localData.msg
		const defaultCategory = {
			sectionId: '',
			itemId: '',
			image: '/images/allCategories.png',
			title: {
				[locale]: getTranslatedTextByKey('webviewFlow.browseCategories', 'Browse Categories'),
			},
		}

		const orderTypeSectionItems = sectionItems[currentOrderType]
		const browseCategories = isEmpty(orderTypeSectionItems) ? [] : [...orderTypeSectionItems, defaultCategory]

		useEffect(() => {
			// On nextJS, AddressManager.localizedAddress is empty during first rendering
			// because it's filled in _app.tsx useEffect (see Application.startNextCSRInit)
			// So we fetch categories again if the user is localized (not required if not, because the first call of fetchCategories will take Infra?.appParams?.c so it will pass also for nextJS)
			// need to improve that part once nextJS will be global
			if (isNextJS() && AddressManager.isUserLocalized()) {
				fetchCategories()
			}
		}, [AddressManager.getFullAddressByOrderType(currentOrderType)])

		const fetchCategories = async () => {
			try {
				const chainOrStoreId = AddressManager.isUserLocalized()
					? AddressManager.getFullAddressByOrderType(currentOrderType).storeId
					: Infra?.appParams?.c

				if (!chainOrStoreId) {
					return
				}

				const channelType = isMobileApp() ? CONSTANTS.CHANNEL_TYPE.APP : CONSTANTS.CHANNEL_TYPE.WEB
				const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
				const res = await Home.getBrowseCategories(chainOrStoreId, channelType, timezone)
				setSectionItems(res || {})
			} catch (err) {
				console.error(err)
			}
		}

		const navigateToSection = async (category: CategoryItemType) => {
			if (AddressManager.isUserLocalized()) {
				const { menuRes, storeMetaData, serverSession, menuUrl } = await AddressManager.onMenuClickWhenLocalized()
				User.setSession(serverSession)
				setStore((store) => ({ ...store, data: menuRes, metaData: storeMetaData }))

				router.push(`${menuUrl}#section_id=${category.sectionId}`)
			} else {
				const lang = User.preferredLanguage
				if (isNextJS()) {
					nextJS.router.push(`/${lang}/menu#section_id=${category?.sectionId}`)
					return
				}

				const url = category.sectionId ? `/${lang}/menu${isMobileApp() ? '' : '.html'}#section_id=${category?.sectionId}` : `/${lang}/menu`
				SmartLink.route(url, router, !Application.isMobileApp, category.sectionId ? '' : undefined)
			}
		}

		return (
			<WithInView
				callbackOnVisible={fetchCategories}
				triggerCallbackInstantly={!Infra?.hasFeatureFlag(FeatureFlagEnum.LAZY_LOAD_BROWSE_CATEGORIES)}
			>
				{!isEmpty(browseCategories) && (
					<Wrapper>
						<SectionTitleWithDivider
							title={getTranslatedTextByKey('webviewFlow.exploreTheMenu', 'Explore Our Menu')}
							testId="browse-categories-title"
						/>
						<ContainerGrid data-testid="browse-categories-container">
							{browseCategories?.map((categoryItem: CategoryItemType, index) => (
								<CategoryItem
									{...categoryItem}
									onClick={() => navigateToSection(categoryItem)}
									title={getLocaleStr(categoryItem?.title, locale)}
									allCategoriesItem={!!categoryItem.sectionId}
									key={categoryItem.itemId}
									testId={`browse-category-item-${index}`}
								/>
							))}
						</ContainerGrid>
					</Wrapper>
				)}
			</WithInView>
		)
	})
)

export default BrowseCategoriesSection
