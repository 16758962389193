// @ts-nocheck
import React, { useContext } from 'react'
import { inject, observer } from 'mobx-react'
import { StoreContext } from 'contexts/StoreContext'
import { isMobile } from '../../utils/utils'
import CheckoutButton from '../common/CheckoutButton'
import version from '../../../version.json'
import styled from 'styled-components'
import TypographyPro from '../../themes/TypographyPro'
import useScrollTrigger from '@material-ui/core/useScrollTrigger'
import Slide from '@material-ui/core/Slide'
import PropTypes from 'prop-types'

const FOOTER_CONSTANTS = {
	PRIVACY_POLICY: 'privacyPolicy',
	TERMS_AND_CONDITIONS: 'termsAndConditions',
	VERSION: 'version',
	RIGHTS_RESERVED: 'allRightsReserved',
	CONTACT_US: 'contactUs',
	STORE_LOCATOR: 'StoreLocator',
}

const StyledFooter = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	background-color: var(--footerAndDarkBackgrounds);
	position: relative;
	height: 83px;

	@media (max-width: 576px) {
		height: 50px;
	}
`

const FooterLinks = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	min-width: 100px;
`

const VersionAndRights = styled.div`
	display: flex;
	flex-direction: column;
	min-width: 100px;
	justify-content: center;
	margin-inline-start: 20px;
`

const StyledText = styled(TypographyPro)`
	display: flex;
	align-items: center;
	text-align: right;
	color: var(--optionalBackground);
`

// why to not use an img tag for css background images:
// https://stackoverflow.com/questions/33145014/css-background-image-shows-unwanted-border-img-src-does-not
const StyledFooterLogo = styled.div`
	height: 100vh;
	width: 100vw;
	max-height: 43px;
	max-width: 125px;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	align-self: center;
	background-image: ${({ logo }) => `url(${logo})`};
	@media (max-width: 576px) {
		background-image: ${({ logo }) => `url(${logo})`};
		max-height: 35px;
		max-width: 97px;
	}
`

const HideOnScroll = (props) => {
	const { isMenu, children } = props
	const spaceToDeductFromTrigger = isMobile() && !isMenu ? document.documentElement.scrollHeight - window.innerHeight : 100
	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: document.documentElement.scrollHeight - window.innerHeight - spaceToDeductFromTrigger,
	})

	const onEntering = (e) => {
		if (isMobile() && isMenu) {
			document.getElementById('checkoutButton').style.bottom = '45px'
		}
	}

	const onExiting = (e) => {
		if (isMenu) {
			document.getElementById('checkoutButton').style.bottom = '0px'
		}
	}

	return isMenu ? (
		<Slide direction="up" in={trigger} onEntering={onEntering} onExiting={onExiting}>
			{children}
		</Slide>
	) : (
		<Slide direction="up" in={trigger}>
			{children}
		</Slide>
	)
}

HideOnScroll.propTypes = {
	children: PropTypes.element.isRequired,
	/**
	 * Injected by the documentation to work in an iframe.
	 * You won't need it on your project.
	 */
}

const _isMobile = isMobile()

const NonEcommerceFooter = inject(
	'User',
	'Cart',
	'Application',
	'Infra'
)(
	observer(({ isMenu, isRTL, User, Cart, Infra, isHomepage, Application }) => {
		const parsed = Infra.appParams
		const { store } = useContext(StoreContext) || {}

		const footerTitles = {
			[FOOTER_CONSTANTS.PRIVACY_POLICY]: User?.userLocaleLanguage.webviewFlow.privacyPolicy,
			[FOOTER_CONSTANTS.TERMS_AND_CONDITIONS]: User?.userLocaleLanguage.webviewFlow.termsAndConditions,
			[FOOTER_CONSTANTS.VERSION]: User?.userLocaleLanguage.webviewFlow.version,
			[FOOTER_CONSTANTS.RIGHTS_RESERVED]: User?.userLocaleLanguage.webviewFlow.allRightsReserved,
			[FOOTER_CONSTANTS.CONTACT_US]: User?.userLocaleLanguage.webviewFlow.contactUs,
			[FOOTER_CONSTANTS.STORE_LOCATOR]: User?.userLocaleLanguage.webviewFlow.storeLocator,
		}

		if (Application.isMobileApp) {
			return null
		}

		let logoUrl
		if (Infra?.appParams?.eCommerce) {
			// this is for ecommerce
			if (_isMobile) {
				logoUrl = Infra?.appParams?.assets?.logoMobileHeader
			} else {
				logoUrl = Infra?.appParams?.assets?.logoDesktopHeader
			}
		} else {
			// this is for non-ecommerce
			logoUrl = Infra.appParams.logo
		}

		return (
			<>
				{isMenu && isMobile() && (
					<CheckoutButton
						currency={store?.data?.currency}
						serverGrandTotal={Cart?.serverGrandTotal}
						cartItems={Cart?.items}
						calcNumberOfItems={Cart?.calcNumberOfItems}
						locale={store?.data?.locale}
					/>
				)}

				<StyledFooter>
					<VersionAndRights>
						<StyledText variant="BodySmall" component="p">{`${footerTitles.version || 'Version'} ${version.version}`}</StyledText>
					</VersionAndRights>
					<StyledFooterLogo $logo={parsed.logo} />
					{!isMenu && <FooterLinks />}
				</StyledFooter>
			</>
		)
	})
)

export default NonEcommerceFooter
