// @ts-nocheck
import React from 'react'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'
import MenuItemLoader from './MenuItemLoader'
import CustomPlaceholderLoader from './CustomPlaceholderLoader'

const StyledLimitedOffersPlaceholderContainer = styled.div`
	display: flex;
	flex-direction: column;
`

const StyledLimitedOffersItemContainer = styled.div`
	display: flex;
`

const LimitedTimeOfferLoader = ({ width, height, viewBox }) => {
	const isMobileView = !useMediaQuery({ query: '(min-width: 576px)' })
	return (
		<StyledLimitedOffersPlaceholderContainer>
			<CustomPlaceholderLoader viewBox={isMobileView ? '0 0 500 26' : '0 0 500 15'} style={isMobileView && { marginBottom: '50px' }}>
				<rect width="183" height="26" fill="black" />
			</CustomPlaceholderLoader>
			<StyledLimitedOffersItemContainer>
				{[...Array(3)].map((_, idx) => (
					<MenuItemLoader key={idx} height={height} width={width} viewBox={viewBox} />
				))}
			</StyledLimitedOffersItemContainer>
		</StyledLimitedOffersPlaceholderContainer>
	)
}

export default LimitedTimeOfferLoader
