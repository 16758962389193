// @ts-nocheck
import React, { useRef, useMemo } from 'react'
import { useInView } from 'react-intersection-observer'
import IconComponent from '../../themes/IconComponent'
import styled from 'styled-components'
import { respondAbove } from 'styles/mixins'
import PropTypes from 'prop-types'
import { css } from 'styled-components/macro'

const CarouselContainer = styled.div`
	width: auto;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 10px;
`

const InnerCarousel = styled.div`
	display: flex;
	overflow-x: ${(props) => (props.$vertical ? 'hidden' : 'auto')};
	overflow-y: ${(props) => (props.$vertical ? 'auto' : 'hidden')};
	flex-flow: ${(props) => (props.$vertical ? 'column' : 'row')} nowrap;
	gap: 20px;
	padding: 30px 0;

	/* hide scrollbar */
	-ms-overflow-style: none; /* Internet Explorer 10+ */
	scrollbar-width: none; /* Firefox */

	&::-webkit-scrollbar {
		display: none; /* Google Chrome & Safari */
	}

	/* disable selection */
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */
`

const CarouselPad = styled.div`
	width: 0;
`

const ArrowButton = styled.div`
	display: none;
	cursor: pointer;

	&.btn-left svg {
		margin-left: 20px;
	}
	&.btn-right svg {
		margin-right: 20px;
	}
	&:hover {
		& path {
			stroke: black;
		}
	}

	${respondAbove.md`
		display: initial;
	`}
`
const LeftArrowIcon = (props) => <IconComponent asset="/icons/leftArrow.svg" {...props} />
const RightArrowIcon = (props) => <IconComponent asset="/icons/rightArrow.svg" {...props} />

/**
 * This carousel is simply div that fits the length of all its child carousel items.
 * It has a hidden scroll-bar, so the user can navigate using touch on mobile or their mouse on desktop.
 * The children are passed in so this carousel can be used wherever it’s needed.
 */
const SimpleCarousel = ({ children, isRtl = false, isVertical = false, ...other }) => {
	// to prevent expensive re-renders the children are memoized, so that when the state changes, children will not.
	const memoizedChildren = useMemo(() => children, [children])
	const carouselRef = useRef(null)

	/*
	useInView is used to simply check if the carousel is scrolled to the end.
	This check is achieved through the tracking of invisible divs (CarouselPad),
	which are on the ends of the inner carousel.
	Whenever one of those divs enters the view, the hook sets the left/rightInView variable,
	which lets us know that we've reached the end of the carousel.
	*/
	const observerOptions = {
		threshold: 1,
		initialInView: false,
		fallbackInView: false,
	}
	const { ref: leftRef, inView: leftInView } = useInView(observerOptions)
	const { ref: rightRef, inView: rightInView } = useInView(observerOptions)

	const SCROLL_AMOUNT_PIXELS = 400

	const scrollCarousel = (direction) => {
		if (carouselRef) {
			const scrollAmount = direction === 'left' ? -SCROLL_AMOUNT_PIXELS : SCROLL_AMOUNT_PIXELS

			carouselRef.current.scrollBy({
				behavior: 'smooth',
				left: scrollAmount,
			})
		}
	}
	const scrollCarouselLeft = () => scrollCarousel('left')
	const scrollCarouselRight = () => scrollCarousel('right')

	const showRightArrow = !isVertical && !rightInView
	const showLeftArrow = !isVertical && !leftInView

	return children ? (
		<CarouselContainer isRtl={isRtl} data-testid="carousel-container" {...other}>
			{showLeftArrow && (
				<ArrowButton className="arrow-btn btn-left" data-testid="left-arrow" onClick={scrollCarouselLeft}>
					<LeftArrowIcon />
				</ArrowButton>
			)}

			<InnerCarousel className="inner-carousel" ref={carouselRef} dir={isRtl ? 'rtl' : 'ltr'} $vertical={isVertical}>
				<CarouselPad ref={isRtl ? rightRef : leftRef} />
				{memoizedChildren}
				<CarouselPad ref={isRtl ? leftRef : rightRef} />
			</InnerCarousel>

			{showRightArrow && (
				<ArrowButton className="arrow-btn btn-right" data-testid="right-arrow" onClick={scrollCarouselRight}>
					<RightArrowIcon />
				</ArrowButton>
			)}
		</CarouselContainer>
	) : null
}

SimpleCarousel.propTypes = {
	children: PropTypes.node,
	isRtl: PropTypes.bool,
	isVertical: PropTypes.bool,
}

export default SimpleCarousel
