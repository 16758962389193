// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react'
import { isMobile } from 'utils/utils'
import MenuItem from '../menu/MenuItem'
import styled from 'styled-components/macro'
import IconComponent from 'themes/IconComponent'
import SectionTitleWithDivider from 'components/common/SectionTitleWithDivider'

// assets

const OffersWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex: 1;
	min-height: 40px;
	z-index: 97;
	${({ $vertical }) => $vertical && 'margin-bottom: unset; margin-top: unset;padding: unset;'};

	@media (max-width: 576px) {
		min-height: 217px;
		padding-top: 0;
		margin-top: 0;
	}
`

const StyledSectionIcon = styled(IconComponent)`
	display: none;
	@media (max-width: 576px) {
		display: inline;
		max-height: 45px;
		max-width: 45px;
		height: 100vh;
		width: 100vw;
		margin-inline-start: 20px;
	}
`

const StyledForwardButton = styled(IconComponent).attrs({ asset: '/icons/rightArrow.svg' })`
	color: #686767;
	font-size: x-large;
	margin-left: 10px;
	cursor: pointer;

	&:hover {
		color: black;
		transition: background-color 300ms linear;
	}
`

const StyledBackButton = styled(StyledForwardButton)`
	transform: rotate(180deg);
	margin-right: 10px;
	margin-left: 20px;
	cursor: pointer;
	&:hover {
		color: black;
		transition: background-color 300ms linear;
	}
`

const SimpleCarousel = styled.div`
	display: -webkit-box;
	overflow-x: auto;
	padding: 5px 20px 68px 20px;
	display: grid;
	grid-template-columns: ${({ $columns }) => `repeat(${$columns}, 1fr)`};
	gap: 20px;

	&::-webkit-scrollbar {
		display: none;
	}

	@media (min-width: 320px) and (max-width: 374px) {
		padding: 5px 10px 60px 10px;
	}

	${({ $vertical }) =>
		$vertical && 'display: flex; flex-direction: column; row-gap: 55px;align-items: center;padding: 0px 0 28px 0; overflow-x: visible;'}
`

const SimpleCarouselWrapper = styled.div`
	display: flex;
	align-items: center;
	padding-inline-end: ${({ $hasPadding }) => ($hasPadding ? 20 : 0)}px;
	${({ $vertical }) => $vertical && 'justify-content: center;'}
`

const TitleCarouselWrapper = styled.div`
	margin-top: 40px;
	margin-bottom: 25px;
	padding: 0 20px;
	${({ $vertical }) => $vertical && 'margin-bottom: 40px; margin-top: unset;text-indent:unset;'};

	@media (min-width: 375px) and (max-width: 899px) {
		padding: 0 20px;
	}

	@media (min-width: 320px) and (max-width: 374px) {
		padding: 0 10px;
	}
`

const ArrowContainer = styled.div`
	margin-bottom: 40px;
`

const LimitedOffersSection = (props) => {
	const { onItemClick, limitedOffers, title, titleIcon, carouselId, deliveryMethod, isDealsPage, currency } = props
	const wrapperRef = useRef(null)
	const [showBackArrow, setShowBackArrow] = useState(false)
	const [showNextArrow, setShowNextArrow] = useState(false)

	const currentElementId = carouselId || 'simpleCarousel'

	// since it is not clear where does the icon for the title will come from (usually it comes from menu.json which is not accessible on home)
	// for future use I had made a titleIcon prop to be received from the homepage in anyway that the server will retrieve it
	const limitedOffersSectionIconUrl = titleIcon

	const back = () => {
		scroll('back')
	}

	const next = () => {
		scroll('next')
	}

	// this method is ONLY used for desktop, not for mobile
	const scroll = (direction) => {
		const SCROLL_AMOUNT_IN_PIXELS = 400
		const elem = document.getElementById(currentElementId)
		const totalScrollableLength = elem.scrollWidth
		const scrollbarTrackLaneLength = elem.offsetWidth
		const scrollBarLeftX = elem.scrollLeft

		// the entire totalScrollableWidth is represented by scrolling the scrollbar so we need to calculate the
		// ratio of the scrollbar size to the total scrollable width size
		const ratio = scrollbarTrackLaneLength / totalScrollableLength

		// the size of the scrollbar itself (not the track lane)
		const scrollbarLength = scrollbarTrackLaneLength * ratio

		// the size of the movement in the scrollbar after applying the ratio
		const scrollBarXAfterRatio = scrollBarLeftX * ratio

		// the size of the scroll in the scrollbar
		const scrollableAmountAfterRatio = SCROLL_AMOUNT_IN_PIXELS * ratio

		if (direction === 'next') {
			setShowBackArrow(true)

			if (scrollBarXAfterRatio + scrollbarLength + scrollableAmountAfterRatio >= scrollbarTrackLaneLength) {
				setShowNextArrow(false)
			}
		} else if (direction === 'back') {
			setShowNextArrow(true)

			if (scrollBarXAfterRatio - scrollableAmountAfterRatio <= 0) {
				setShowBackArrow(false)
			}
		}

		document.getElementById(currentElementId).scrollBy({
			behavior: 'smooth',
			left: direction === 'back' ? -SCROLL_AMOUNT_IN_PIXELS : SCROLL_AMOUNT_IN_PIXELS,
		})
	}

	useEffect(() => {
		const _isMobile = isMobile(false)

		if (!_isMobile && limitedOffers.length > 0) {
			// we want to stop the user from scrolling with their mouse/trackpad AND only scroll using the on-screen arrows
			const elem = document.getElementById(currentElementId)
			if (elem) {
				elem.addEventListener('scroll', preventScrollByMouse)
				elem.addEventListener('mousewheel', preventScrollByMouse)
				elem.addEventListener('DOMMouseScroll', preventScrollByMouse)
			}
			const arrowsVisibility = needScrollArrows()
			if (arrowsVisibility) {
				setShowNextArrow(true)
				// there are more items in the carousel than fit the current width so add the 'next' arrrow
				scroll('back')
				document.getElementById(currentElementId).style.width = ''
			} else {
				// center the simpleCarousel div
				setShowBackArrow(false)
				setShowNextArrow(false)
				if (!isDealsPage) {
					document.getElementById(currentElementId).style.width = 'auto'
				}
			}
		}

		return () => {
			if (!_isMobile && limitedOffers.length > 0) {
				const elem = document.getElementById(currentElementId)
				if (elem) {
					elem.removeEventListener('scroll', preventScrollByMouse)
					elem.removeEventListener('mousewheel', preventScrollByMouse)
					elem.removeEventListener('DOMMouseScroll', preventScrollByMouse)
				}
			}
		}
	}, [deliveryMethod, limitedOffers.length])

	const preventScrollByMouse = (e) => {
		if (e.deltaY === 0) {
			e.preventDefault()
			e.stopPropagation()
			return false
		}

		return true
	}

	const needScrollArrows = () => {
		const padding = 10
		const container = document.getElementById(currentElementId)

		// count only the direct div children of this parent, NOT all grandchild divs etc
		const divs = container.getElementsByTagNameNS('http://www.w3.org/1999/xhtml', 'div')

		const offersAmount = limitedOffers.length
		const divWidth = divs[0].offsetWidth + padding * 2

		if (isDealsPage && divWidth * offersAmount >= (wrapperRef.current?.clientWidth ?? 0) * 0.45) {
			return true
		}

		if (divWidth * offersAmount <= wrapperRef.current?.clientWidth) {
			return false
		}

		return true
	}

	return (
		<OffersWrapper dir="ltr" $vertical={props.vertical} ref={wrapperRef} data-testid="lto-container">
			{limitedOffers.length > 0 && (
				<>
					<TitleCarouselWrapper>
						{limitedOffersSectionIconUrl && <StyledSectionIcon assetFullUrl={limitedOffersSectionIconUrl} />}
						<SectionTitleWithDivider title={title} testId="lto-title" />
					</TitleCarouselWrapper>

					<SimpleCarouselWrapper $vertical={props.vertical} $hasPadding={showNextArrow}>
						{showBackArrow && (
							<ArrowContainer data-testid="lto-left-arrow" onClick={back}>
								<StyledBackButton />
							</ArrowContainer>
						)}
						<SimpleCarousel id={currentElementId} $vertical={props.vertical} $columns={limitedOffers.length}>
							{limitedOffers.map((item, index) => (
								<MenuItem
									index={index}
									key={item.id}
									item={item}
									sectionTitle={title}
									quantity={1}
									limitedOffersItemClick={onItemClick}
									limitedOffersStore={props.randomStore}
									isLimitedOffersVertical={props.vertical}
									currency={currency}
								/>
							))}
						</SimpleCarousel>

						{showNextArrow && (
							<ArrowContainer onClick={next} data-testid="lto-right-arrow">
								<StyledForwardButton />
							</ArrowContainer>
						)}
					</SimpleCarouselWrapper>
				</>
			)}
		</OffersWrapper>
	)
}

export default LimitedOffersSection
