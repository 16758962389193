import React from 'react'
import { useMediaQuery } from 'react-responsive'
import CustomPlaceholderLoader from './CustomPlaceholderLoader'

const MenuItemLoader = ({ width, height, viewBox }) => {
	const isMobileView = !useMediaQuery({ query: '(min-width: 576px)' })
	return isMobileView ? (
		<CustomPlaceholderLoader width={width || 160} height={height || 214} viewBox={viewBox || '0 0 160 214'}>
			<rect width="160" height="214" rx="2" fill="black" />
		</CustomPlaceholderLoader>
	) : (
		<CustomPlaceholderLoader width={width || 260} height={height || 285} viewBox={viewBox || '0 0 260 285'} style={{ margin: '0 10px' }}>
			<rect width="260" height="285" fill="black" />
		</CustomPlaceholderLoader>
	)
}

export default MenuItemLoader
