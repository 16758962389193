import React, { useRef } from 'react'
import styled, { css } from 'styled-components'
import TypographyPro from 'themes/TypographyPro'
import ImageComponent from 'themes/ImageComponent'
import { useInView } from 'react-intersection-observer'
import { spaceToDash } from 'utils/utils'

const Wrapper = styled.div`
	cursor: pointer;
	${({ $isVisible }: { $isVisible: boolean }) =>
		$isVisible &&
		css`
			// animation: fadeIn 2s;
			visibility: visible;
		`}

	@keyframes fadeIn {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
`

const ImageContainer = styled.div`
	background-color: var(--clear);
	box-shadow: var(--defaultShadow1);
	aspect-ratio: 191 / 100;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	overflow: hidden;
`

const Title = styled(TypographyPro)`
	text-align: center;
	width: 100%;
	color: var(--strokeMenuTitle);
	margin-top: 5px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`

const CategoryItemImage = styled(ImageComponent)`
	width: 100%;
	height: 100%;
	transition-duration: 0.3s !important;
	transition-property: unset !important;
	transition-timing-function: ease-in-out !important;
	object-fit: cover;

	:hover {
		transform: scale(1.05);
		transition: transform 0.3s;
	}
`

interface CategoryItemProps {
	image: string
	title: string
	onClick: () => void
	allCategoriesItem: boolean
	testId: string
}

const CategoryItem = ({ image, title, onClick, allCategoriesItem, testId }: CategoryItemProps) => {
	const { ref, inView } = useInView()

	const categoryItemImageProps = {
		[allCategoriesItem ? 'assetFullUrl' : 'asset']: image,
		alt: image,
	}

	return (
		<Wrapper
			id={`home-page-explore-${spaceToDash(title)}-click`}
			$isVisible={inView}
			onClick={onClick}
			role="button"
			ref={ref}
			data-testid={testId}
		>
			<ImageContainer>
				<CategoryItemImage {...categoryItemImageProps} testId="browse-category-item-img" />
			</ImageContainer>
			<Title variant="h5" data-testid="browse-category-item-title">
				{title}
			</Title>
		</Wrapper>
	)
}

export default CategoryItem
