// @ts-nocheck
import React from 'react'
import styled from 'styled-components'
import { laptop } from '../../styles/mixins'

const Title = styled.h3`
	position: relative;
	${({ theme }) => theme.typography.h2};
	color: var(--strokeMenuTitle);
	overflow: hidden;
	text-transform: uppercase;
	margin: 0;

	&:after {
		content: '';
		display: inline-block;
		height: 2px;
		vertical-align: middle;
		width: 100%;
		margin-inline-end: -100%;
		margin-inline-start: 20px;
		background-color: var(--optionalBackground);

		${laptop}: {
			margin-inline-start: 30px;
		}
	}
`

const SectionTitleWithDivider = ({ title, className = '', testId = 'title' }) =>
	title && (
		<Title className={className} data-testid={testId}>
			{title}
		</Title>
	)

export default SectionTitleWithDivider
