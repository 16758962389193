import styled from 'styled-components'

// This is used to wrap components and adjust the padding in different screen sizes
export default styled.div<{ maxWidth?: boolean }>`
	width: 100%;
	max-width: ${({ maxWidth = true }) => (maxWidth ? '1280px' : 'unset')};

	// small mobile
	@media (min-width: 320px) and (max-width: 374px) {
		padding: 0 10px;
	}

	// mobile
	@media (min-width: 375px) and (max-width: 599px) {
		padding: 0 20px;
	}

	// tablet/laptop
	@media (min-width: 600px) and (max-width: 899px) {
		padding: 0 20px;
	}

	// desktop
	@media (min-width: 900px) {
		padding: 0 40px;
	}
`
