// @ts-nocheck
import React from 'react'
import styled, { css } from 'styled-components/macro'
import TypographyPro from '../../themes/TypographyPro'
import { inject } from 'mobx-react'

const StyledSubHeader = styled(TypographyPro)`
	display: flex;
	flex-direction: ${({ $isLimitedOffers, $isRTL }) => ($isLimitedOffers && $isRTL ? 'row-reverse' : 'row')};
	align-items: center;
	background-color: var(--footerAndDarkBackgrounds);
	width: 100%;
	color: var(--clear);
	min-height: 46px;
	height: auto;
	justify-content: center;
	z-index: 1;
	margin-top: -10px;
	padding: unset;
	padding-top: 20px;
	padding-bottom: 10px;

	box-shadow: ${({ $isStoreClosed }) => $isStoreClosed && 'var(--highlightShadow1)'};
	@media (max-width: 768px) {
		flex-direction: ${({ $direction }) => $direction};
		align-items: ${({ $direction }) => $direction === 'column' && 'flex-start'};
	}
`

// You might want to check StaticSubHeader for additional rules and behaviour
const SubHeader = inject('Application')((props) => {
	const { isLimitedOffers, isRTL, children, className, direction, $isStoreClosed } = props
	return (
		<StyledSubHeader
			data-testid="header-bar"
			variant="BodySmall"
			component="div"
			id="header-bar"
			$isLimitedOffers={isLimitedOffers}
			$isRTL={isRTL}
			className={className}
			$direction={direction}
			$isStoreClosed={$isStoreClosed}
		>
			{children}
		</StyledSubHeader>
	)
})

export default SubHeader
